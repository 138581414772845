<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9" @submit.prevent="setData()">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-10">
                        <h2 class="mb-0" v-if="modo == 'Create'">New Study</h2>
                        <h2 class="mb-0" v-else >Edit Study</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-2">
                        <div class="d-flex">
                            <button type="submit" class="btn btn-primary px-5 w-100 text-nowrap">Save</button>
                        </div>
                    </div>
                    
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="study.initial_year" type="number" placeholder="Initial Year" />
                                    <label for="floatingEventInput">Initial Year</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="study.final_year" type="number" placeholder="Final Year" />
                                    <label for="floatingEventInput">Final Year</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="study.degree" type="text" placeholder="Degree" />
                                    <label for="floatingEventInput">Degree</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="study.institution" type="text" placeholder="Institution" />
                                    <label for="floatingEventInput">Institution</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="study.place" type="text" placeholder="Place" />
                                    <label for="floatingEventInput">Place</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="study.country" type="text" placeholder="Country" />
                                    <label for="floatingEventInput">Country</label>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import SideBar from '@/components/SideBarNav.vue'
import TopBar from '@/components/TopBarNav.vue'
import axios from 'axios'
export default {
    name:'StudyAdminComponent',
    components:{SideBar,TopBar},
    data(){
        return{
            study: {},
            modo: this.$route.params.id > 0 ? 'Edit' : 'Create'
        }
    },
    created(){
        if(this.$route.params.id){
            this.getData()
        }
    },
    methods:{
        getData(){
             axios.get(this.$url+'/study/'+this.$route.params.id, {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then((res)=>{
                this.study = res.data
            })
        },
        setData(){
             axios.post(this.$url+(this.modo == 'Create' ? '/study' : '/study/'+this.$route.params.id), this.study,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then(()=>{
                this.$router.push({name: 'studies'})
            })
        }
    }
}
</script>