<template>
    <section >
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-10">
                        <h2 class="mb-0">Site Information</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-2">
                    <div class="d-flex">
                        <button @click="setData()" type="button" class="btn btn-primary w-100 text-nowrap">Save</button>
                    </div>
                    </div>
                    
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            
                            <div class="col-sm-12 col-md-12 text-center">
                                <div class="avatar avatar-5xl ">
                                    <label for="featured" class="cursor-pointer" >
                                        <img v-if="artist.profile" class="rounded-soft" :src="$url+'/getImageContent'" alt="" />
                                        <img v-else class="rounded-soft" :src="profile" alt="sdf" />
                                    </label>
                                </div>
                                <input class=" d-none" id="featured" type="file"  @change="uploadImage($event)" />
                            </div>
                           

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating">
                                    <textarea class="form-control" v-model="artist.bio_es" placeholder="Biography Spanish" style="height:150px"></textarea>
                                    <label for="floatingEventInput">Bio Spanish</label>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating">
                                    <textarea class="form-control" v-model="artist.bio_en" placeholder="Biography English" style="height:150px"></textarea>
                                    <label for="floatingEventInput">Bio English</label>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>

import SideBar from '@/components/SideBarNav.vue'
import TopBar from '@/components/TopBarNav.vue'
import axios from 'axios'
  
export default {
    name:'ArtistAdminComponent',
    components:{SideBar,TopBar},
    data(){
        return{
            artist: {},
            profile:'/assets/no-image.png'
        }
    },
    created(){
        this.getData()
    },
    methods:{
        getData(){
             axios.get(this.$url+'/artist', {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then((res)=>{
                this.artist = res.data
            })
        },
        setData(){
            var data
            var content = ''
            if(this.profile != undefined && this.profile != '/assets/no-image.png'){
                content = 'multipart/form-data'
                let data = new FormData()

                data.append('bio_es',this.artist.bio_es)
                data.append('bio_en',this.artist.bio_en)
               
                data.append('profile',this.artist.profile)
            }else{
                content = 'application/json'
                data = this.artist
            }
            axios.post(this.$url+'/artist', data,{
                headers: {
                    'Content-Type' : content,
                    'Authorization' : this.$store.state.token
                }
            }).then(()=>{
                console.log('done')
            })
        },
        uploadImage($event){
            var image 
            if($event.target.files.length >= 1){
                image =  $event.target.files[0]
                console.log(image)
            }
            
            if(image.size <= 1000000){
                this.profile = URL.createObjectURL(image)
                if( image.type == 'image/jpg' || image.type == 'image/jpeg' || image.type == 'image/png'){
                    this.artist.profile = image
                }else{
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'El tipo de archivo no se permite',
                        type: 'danger'
                    });
                    this.profile = undefined
                }
            }else{
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: 'El archivo debe pesar menos de 1Mb',
                    type: 'danger'
                });
                this.profile = undefined
            }
        }
    }
}
</script>