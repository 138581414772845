<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9" @submit.prevent="setData()">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-10">
                        <h2 class="mb-0" v-if="modo == 'Create'">New Exhibition</h2>
                        <h2 class="mb-0" v-else >Edit Exhibition</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-2">
                        <div class="d-flex">
                            <button type="submit" class="btn btn-primary px-5 w-100 text-nowrap">Save</button>
                        </div>
                    </div>
                    
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="exhibition.name" type="text" placeholder="Name" />
                                    <label for="floatingEventInput">Name</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="exhibition.gallery" type="text" placeholder="Gallery" />
                                    <label for="floatingEventInput">Gallery</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="exhibition.city" type="text" placeholder="City" />
                                    <label for="floatingEventInput">City</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="exhibition.country" type="text" placeholder="Country" />
                                    <label for="floatingEventInput">Country</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="exhibition.initial_date" type="date" placeholder="Initial date" />
                                    <label for="floatingEventInput">Initial date</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="exhibition.final_date" type="date" placeholder="Final date" />
                                    <label for="floatingEventInput">Final date</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="exhibition.year" type="number" placeholder="Year" />
                                    <label for="floatingEventInput">Year</label>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import SideBar from '@/components/SideBarNav.vue'
import TopBar from '@/components/TopBarNav.vue'
import axios from 'axios'
export default {
    name:'ExhibitionAdminComponent',
    components:{SideBar,TopBar},
    data(){
        return{
            exhibition: {},
            modo: this.$route.params.id > 0 ? 'Edit' : 'Create'
        }
    },
    created(){
        if(this.$route.params.id){
            this.getData()
        }
    },
    methods:{
        getData(){
             axios.get(this.$url+'/exhibition/'+this.$route.params.id, {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then((res)=>{
                this.exhibition = res.data
            })
        },
        setData(){
             axios.post(this.$url+(this.modo == 'Create' ? '/exhibition' : '/exhibition/'+this.$route.params.id), this.exhibition,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then(()=>{
                this.$router.push({name: 'exhibitions'})
            })
        }
    }
}
</script>