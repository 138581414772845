<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-10">
                        <h2 class="mb-0" v-if="modo == 'Create'">New Category</h2>
                        <h2 class="mb-0" v-else >Edit Category</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-2">
                        <div class="d-flex">
                            <button @click="setData()" type="button" class="btn btn-primary px-5 w-100 text-nowrap">Save</button>
                        </div>
                    </div>
                    
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="category.name_es" type="text" placeholder="Name ES" />
                                    <label for="floatingEventInput">Name ES</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="category.name_en" type="text" placeholder="Name EN" />
                                    <label for="floatingEventInput">Name EN</label>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import SideBar from '@/components/SideBarNav.vue'
import TopBar from '@/components/TopBarNav.vue'
import axios from 'axios'
export default {
    name:'CategoryAdminComponent',
    components:{SideBar,TopBar},
    data(){
        return{
            category: {},
            modo: this.$route.params.id > 0 ? 'Edit' : 'Create'
        }
    },
    created(){
        if(this.$route.params.id){
            this.getData()
        }
    },
    methods:{
        getData(){
             axios.get(this.$url+'/category/'+this.$route.params.id, {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then((res)=>{
                this.category = res.data
            })
        },
        setData(){
             axios.post(this.$url+(this.modo == 'Create' ? '/category' : '/category/'+this.$route.params.id), this.category,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then(()=>{
                this.$router.push({name: 'categories'})
            })
        }
    }
}
</script>