<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9" @submit.prevent="setData()">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-10">
                        <h2 class="mb-0" v-if="modo == 'Create'">New Featured</h2>
                        <h2 class="mb-0" v-else >Edit Featured</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-2">
                        <div class="d-flex">
                            <button type="submit" class="btn btn-primary px-5 w-100 text-nowrap">Save</button>
                        </div>
                    </div>
                    
                </div>
                <div class="row g-5">
                    <div class="col-xl-12">
                        <div class="row gx-3 gy-4">
                            <div class="col-sm-12 col-md-6">
                                <div class="avatar avatar-5xl">
                                    <label for="featured" class="cursor-pointer" >
                                        <img class="rounded" :src="image" alt="" />
                                    </label>
                                </div>
                                <input class="d-none" id="featured" type="file" @change="uploadImage($event)" />
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="featured.title_en" type="text" placeholder="Title EN" />
                                    <label for="floatingEventInput">Title EN</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="featured.title_es" type="text" placeholder="Title ES" />
                                    <label for="floatingEventInput">Title ES</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <textarea class="form-control" v-model="featured.description_en" placeholder="Description EN" style="height:150px"></textarea>
                                    <label for="floatingEventInput">Description EN</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <textarea class="form-control" v-model="featured.description_es" placeholder="Description ES" style="height:150px"></textarea>
                                    <label for="floatingEventInput">Description ES</label>
                                </div>
                            </div>  

                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="featured.link_text_en" type="text" placeholder="Link EN" />
                                    <label for="floatingEventInput">Link Text EN</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="featured.link_text_es" type="text" placeholder="Link ES" />
                                    <label for="floatingEventInput">Link Text ES</label>
                                </div>
                            </div>  
                           
                            <div class="col-sm-12 col-md-6">
                                <div class="form-check">
                                    <input class="form-check-input" id="flexCheckChecked" type="checkbox" v-model="featured.is_active" :value="true" checked="" />
                                    <label class="form-check-label" for="flexCheckChecked">Visible at home</label>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import SideBar from '@/components/SideBarNav.vue'
import TopBar from '@/components/TopBarNav.vue'
import axios from 'axios'
export default {
    name:'featuredAdminComponent',
    components:{SideBar,TopBar},
    data(){
        return{
            featured: {},
            modo: this.$route.params.id > 0 ? 'Edit' : 'Create',
            image:'/assets/no-image.png',
            imageChanged: false,
        }
    },
    created(){
        if(this.$route.params.id){
            this.getData()
        }
    },
    methods:{
        getData(){
             axios.get(this.$url+'/featuredadmin/'+this.$route.params.id, {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then((res)=>{
                this.featured = res.data
            })
        },
        setData(){
            let data
            var content = ''
            if( this.imageChanged == true && ( this.image != undefined && this.image != '/assets/no-image.png')){

                content = 'multipart/form-data'
                data = new FormData()

                data.append('title_en',this.featured.title_en)
                data.append('title_es',this.featured.title_es)
                data.append('description_en',this.featured.description_en)
                data.append('description_es',this.featured.description_es)
                data.append('link_text_en',this.featured.link_text_en)
                data.append('link_text_es',this.featured.link_text_es)
                data.append('is_active',this.featured.is_active ? 1 : 0)
              
                data.append('image',this.featured.image)
            }else{
                content = 'application/json'
                data = this.featured
            }
            axios.post(this.$url+(this.modo == 'Create' ? '/featureds' : '/featureds/'+this.$route.params.id),data,{
                headers: {
                    'Content-Type' : content,
                    'Authorization' : this.$store.state.token
                }
            }).then(()=>{
                this.$router.push({name: 'featured'})
            })

        },
        uploadImage($event){
            var image 
            if($event.target.files.length >= 1){
                image = $event.target.files[0]
            
                if(image.size <= 3000000){
                    if(image.type == 'image/jpeg' || image.type == 'image/png' || image.type == 'image/webp' || image.type == 'image/jpg'){
                        this.image = URL.createObjectURL(image)
                        this.featured.image = image
                        this.imageChanged = true
                    }else{
                        this.$notify({
                            group: 'iasc',
                            title: 'Error',
                            text: 'El tipo de archivo no se permite',
                            type: 'danger'
                        });
                    }
                }else{
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'El archivo debe pesar menos de 1Mb',
                        type: 'danger'
                    });
                }
            }
        }
    }
}
</script>
