<template>

<main class="main" id="top">
      <div class="container-fluid px-0">
        <div class="container">
          <div class="row flex-center min-vh-100 py-5">
            <div class="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
              <router-link class="d-flex flex-center text-decoration-none mb-4" to="/login">
                <div class="d-flex align-items-center fw-bolder fs-5 d-inline-block">
                  <img src="@/assets/logo-studio.png" alt="Marina SaMont" width="128" />
                </div>
              </router-link>
              <div class="text-center mb-7">
                <h3 class="text-1000">Sign Up</h3>
              </div>
             
              <form @submit.prevent="validar()">
                
                <div class="mb-3 text-start">
                  <label class="form-label" for="email">Email address</label>
                  <input class="form-control" id="email" v-model="user.email" type="email" placeholder="name@example.com" />
                </div>
                <div class="row g-3 mb-3">
                  <div class="col-md-6">
                    <label class="form-label" for="password">Password</label>
                    <input class="form-control form-icon-input" v-model="user.password"  id="password" type="password" placeholder="Password" />
                  </div>
                  <div class="col-md-6">
                    <label class="form-label" for="confirmPassword">Confirm Password</label>
                    <input class="form-control form-icon-input" id="confirmPassword"  v-model="user.confirm_password"  type="password" placeholder="Confirm Password" />
                  </div>
                </div>
                <button type="submit" class="btn btn-primary w-100 mb-3">Sign up</button>
                <div class="text-center"><router-link class="fs--1 fw-bold" to="/login">Sign in to an existing account</router-link></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>

  </template>
  
  <script>
  import axios from 'axios'
  export default {
    name: 'LoginView',
    data(){
        return{
            user:{},
            msg_err: '',
        }
    },
    methods:{
        validar(){
            if(!this.user.email){
                this.msg_err = 'Enter email'
            }else if(!this.user.password){
                this.msg_err = 'Enter password'
            }else if(!this.user.confirm_password){
                this.msg_err = 'Enter password confirmation'
            }else if((this.user.confirm_password != '' && this.user.password!= '') && this.user.confirm_password != this.user.password){
                this.msg_err = 'Password doesn´t match, please try again'
            }else{
                this.msg_err = ''
                this.createAccount()
            }
        },
        createAccount(){
          let data = {
              email: this.user.email,
              password: this.user.password,
              confirm_password: this.user.confirm_password
          }
          axios.post(this.$url+'/user/create-account',data,{
              headers: {
                'Content-Type' : 'application/json'
              }
          }).then((res)=>{
              if(res.data.token){
                this.msg_err= ''
                this.$router.push({name: 'login'})
              }
          }).catch((err)=>{
              this.msg_err= err
          })
        }
    }
  }
  </script>
  