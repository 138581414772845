<template>
  <nav class="navbar navbar-vertical navbar-expand-lg">
    <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
      
      <div class="navbar-vertical-content">
        <ul class="navbar-nav flex-column" id="navbarVerticalNav">
         
          <li class="nav-item">
            <!-- label-->
            <p class="navbar-vertical-label">Archives</p>
            <hr class="navbar-vertical-line" />
            <!-- parent pages-->
             <!-- parent pages-->
             <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/artworks" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><PaletteIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Artworks</span></span>
                </div>
              </router-link>
            </div>
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/contents" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><AccountDetailsIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Artist</span></span>
                </div>
              </router-link>
            </div>           
          </li>
          <li class="nav-item">
            <!-- label-->
            <p class="navbar-vertical-label">Operaciones</p>
            <hr class="navbar-vertical-line" />
            
              <!-- parent pages-->
              <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/exhibitions" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><PresentationIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Exhibitions</span></span>
                </div>
              </router-link>
            </div>
             <!-- parent pages-->
             <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/news" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><NewspaperIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">News</span></span>
                </div>
              </router-link>
            </div>
            <!-- parent pages-->
            <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/studies" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><BookmarkBoxMultipleIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Studies</span></span>
                </div>
              </router-link>
            </div>
             <!-- parent pages-->
             <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/categories" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><ShapeIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Categories</span></span>
                </div>
              </router-link>
            </div>
           <!-- parent pages-->
           <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/collections" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><BookmarkBoxMultipleIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Collections</span></span>
                </div>
              </router-link>
            </div>
             <!-- parent pages-->
             <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/featureds" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><PlaylistStarIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Featureds</span></span>
                </div>
              </router-link>
            </div>
              <!-- parent pages-->
              <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/sales" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><PlayBoxOutlineIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Sales Contacts</span></span>
                </div>
              </router-link>
            </div>
             <!-- parent pages-->
             <div class="nav-item-wrapper">
              <router-link class="nav-link label-1" active-class="active" to="/sliders" role="button" data-bs-toggle="" aria-expanded="false">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><PlayBoxOutlineIcon /></span><span class="nav-link-text-wrapper"><span class="nav-link-text">Sliders</span></span>
                </div>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="navbar-vertical-footer">
      <button class="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center">
        <span class="uil uil-left-arrow-to-left fs-0"><ArrowCollapseLeftIcon /></span>
        <span class="uil uil-arrow-from-right fs-0"><ArrowCollapseRightIcon /></span>
        <span class="navbar-vertical-footer-text ms-2">Colapsar</span>
      </button>
    </div>
  </nav>
</template>

<script>
import ChartPieOutlineIcon from 'vue-material-design-icons/ChartPieOutline.vue';
import TagMultipleOutlineIcon from 'vue-material-design-icons/TagMultipleOutline.vue';
import MonitorDashboardIcon from 'vue-material-design-icons/MonitorDashboard.vue';
import AccountGroupOutlineIcon from 'vue-material-design-icons/AccountGroupOutline.vue';
import OfficeBuildingIcon from 'vue-material-design-icons/OfficeBuilding.vue';
import ChartTimelineIcon from 'vue-material-design-icons/ChartTimeline.vue';
import AccountKeyIcon from 'vue-material-design-icons/AccountKey.vue';
import ShapeOutlineIcon from 'vue-material-design-icons/ShapeOutline.vue';
import FormatListBulletedTypeIcon from 'vue-material-design-icons/FormatListBulletedType.vue';
import StarSettingsOutlineIcon from 'vue-material-design-icons/StarSettingsOutline.vue';
import StateMachineIcon from 'vue-material-design-icons/StateMachine.vue';
import ArrowCollapseRightIcon from 'vue-material-design-icons/ArrowCollapseRight.vue';
import ArrowCollapseLeftIcon from 'vue-material-design-icons/ArrowCollapseLeft.vue';
import PresentationIcon from 'vue-material-design-icons/Presentation.vue';
import PaletteIcon from 'vue-material-design-icons/Palette.vue';
import AccountDetailsIcon from 'vue-material-design-icons/AccountDetails.vue';
import PlayBoxOutlineIcon from 'vue-material-design-icons/PlayBoxOutline.vue';
import NewspaperIcon from 'vue-material-design-icons/Newspaper.vue';
import PlaylistStarIcon from 'vue-material-design-icons/PlaylistStar.vue';
import ShapeIcon from 'vue-material-design-icons/Shape.vue';
import BookmarkBoxMultipleIcon from 'vue-material-design-icons/BookmarkBoxMultiple.vue';

export default {
    name: 'SideBarNav',
    components:{PresentationIcon,ShapeIcon,BookmarkBoxMultipleIcon,PlayBoxOutlineIcon,PlaylistStarIcon,NewspaperIcon,AccountDetailsIcon,PaletteIcon,ChartPieOutlineIcon,OfficeBuildingIcon,AccountKeyIcon,ShapeOutlineIcon,ArrowCollapseLeftIcon,ArrowCollapseRightIcon,StarSettingsOutlineIcon,StateMachineIcon,FormatListBulletedTypeIcon,TagMultipleOutlineIcon,MonitorDashboardIcon,AccountGroupOutlineIcon,ChartTimelineIcon},
  }
</script>