<template>

  <main class="main" id="top">

    <div class="container-fluid px-0">
        <div class="container">
          <div class="row flex-center min-vh-100 py-5">
            <div class="col-sm-10 col-md-8 col-lg-5 col-xl-5 col-xxl-3">
              <form @submit.prevent="validate()">
                <div class="text-center mb-5">
                  <div class="alert alert-danger" v-if="msg_err" role="alert">
                    {{msg_err}}
                  </div>
                
                  <div class="avatar mb-4"><img src="@/assets/logo-studio.png" alt="Marina SaMont" /></div>
                  
                  <p class="text-700">Enter your password to access the admin</p>
                </div>
                <input class="form-control mb-3" id="password" v-model="password" type="password" placeholder="Enter Password" />
                <button type="submit" class="btn btn-primary w-100">Sign In</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>

  </template>
  
  <script>
  // @ is an alias to /src
  import axios from 'axios'
  export default {
    name: 'LoginView',
    data(){
        return{
            password: '',
            msg_err: ''
        }
    },
    methods:{
        validate(){
            if(!this.password){
                this.msg_err = 'Enter your password'
            }else{
                this.msg_err = ''
                this.login()
            }
        },
        login(){
          axios.post(this.$url+'/admin-user/login',{password: this.password},{
              headers: {
                  'Content-Type' : 'application/json'
              }
          }).then((res)=>{
              if(res.data.token){
                  this.msg_err= ''
                  this.$store.dispatch('saveToken',res.data.token)
                  this.$router.push({name: 'artworks'})
              }
          }).catch((err)=>{
              this.msg_err= err
          })
        }
    }
  }
  </script>
  