<template>
   
</template>

<script>
import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';

export default{
    components:{ SideBar,TopBar}
}
</script>