<template>
  <div id="app">
    <main class="main" id="top">
      <div class="container-fluid px-0" data-layout="container">
          <notifications  group="iasc" />
          <router-view />

          {{ message }}
      </div>
    </main>
  </div>
</template>

<script>
 
  export default {
   
    data(){
      return{
        year: new Date().getFullYear
      }
    },
    created(){
      if(typeof context !== 'undefined' && typeof context.message !== 'undefined')
      {
          this.message = context.message;
      }
    }
  }
</script>