<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <form class="mb-9">
                <div class="row justify-content-between align-items-end g-3 mb-5">
                    <div class="col-12 col-sm-auto col-xl-10">
                        <h2 class="mb-0" v-if="modo == 'Create'">New Collection</h2>
                        <h2 class="mb-0" v-else >Edit Collection</h2>
                    </div>
                    <div class="col-12 col-sm-auto col-xl-2">
                        <div class="d-flex">
                            <button @click="setData()" type="button" class="btn btn-primary px-5 w-100 text-nowrap">Save</button>
                        </div>
                    </div>
                </div>
                <div class="row g-5">
                    <div class="col-3">
                        <div class="avatar avatar-5xl">
                            <label for="featured" class="cursor-pointer" >
                                <img class="rounded" :src="images.featured" alt="" />
                            </label>
                        </div>
                        <input class="d-none" id="featured" type="file" @change="uploadImage($event,'featured')" />
                    </div>
                    <div class="col-xl-9">
                        <div class="row gx-3 gy-4">
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="collection.name" type="text" placeholder="Name" />
                                    <label for="floatingEventInput">Name</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <input class="form-control" v-model="collection.date" type="date" placeholder="Date" />
                                    <label for="floatingEventInput">Date</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <select class="form-control " v-model="collection.is_private" placeholder="Privacy" >
                                        <option value="1">Private</option>
                                        <option value="0">Show in Porfolio</option>
                                    </select>
                                    <label for="floatingEventInput">Privacy</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <select class="form-control " v-model="collection.is_individual" placeholder="Individual" >
                                        <option value="1">Individual</option>
                                        <option value="0">No show</option>
                                    </select>
                                    <label for="floatingEventInput">Individual</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <textarea class="form-control" v-model="collection.description_en" rows="5" style="min-height: 20vh" placeholder="Description EN" ></textarea>
                                    <label for="floatingEventInput">Description EN</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <textarea class="form-control" v-model="collection.description_es" rows="5" style="min-height: 20vh" placeholder="Description ES" ></textarea>
                                    <label for="floatingEventInput">Description ES</label>
                                </div>
                            </div>  
                            <div class="col-sm-12 col-md-6">
                                <div class="form-floating">
                                    <select class="form-control " v-model="collection.text_position" placeholder="Text Position" >
                                        <option value="l">left</option>
                                        <option value="c">center</option>
                                        <option value="r">right</option>
                                    </select>
                                    <label for="floatingEventInput">Text Position</label>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import SideBar from '@/components/SideBarNav.vue'
import TopBar from '@/components/TopBarNav.vue'
import axios from 'axios'
export default {
    name:'CollectionAdminComponent',
    components:{SideBar,TopBar},
    data(){
        return{
            collection: {},
            imageChanged: false,
            modo: this.$route.params.id > 0 ? 'Edit' : 'Create',
            images:{
                featured:'/assets/no-image.png'
            }
        }
    },
    created(){
        if(this.$route.params.id){
            this.getData()
        }
    },
    methods:{
        getData(){
             axios.get(this.$url+'/collection/'+this.$route.params.id, {
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            }).then((res)=>{
                this.collection = res.data
            })
        },
        setData(){
            let data
            var content = ''
            if( this.imageChanged == true && (this.images.featured != undefined && this.images.featured != '/assets/no-image.png') ){
                content = 'multipart/form-data'
                data = new FormData()
                data.append('name',this.collection.name)
                data.append('date',this.collection.date)
                data.append('is_private',this.collection.is_private)
                data.append('is_individual',this.collection.is_individual)
                data.append('description_en',this.collection.description_en)
                data.append('description_es',this.collection.description_es)
                data.append('text_position',this.collection.text_position)
                data.append('featured',this.collection.featured)
               
            }else{
                content = 'application/json'
                data = this.collection
            }
            axios.post(this.$url+(this.modo == 'Create' ? '/collection' : '/collection/'+this.$route.params.id), this.collection,{
                headers: {
                    'Content-Type' : content,
                    'Authorization' : this.$store.state.token
                }
            }).then(()=>{
                this.$router.push({name: 'collections'})
            })
        },
        uploadImage($event,name){
            var image 
            if($event.target.files.length >= 1){
                image = $event.target.files[0]
            
                if(image.size <= 3000000){
                    if(image.type == 'image/jpeg' || image.type == 'image/png' || image.type == 'image/webp' || image.type == 'image/jpg'){
                        this.images[name] = URL.createObjectURL(image)
                        this.collection[name] = image
                        this.imageChanged = true
                    }else{
                        this.$notify({
                            group: 'iasc',
                            title: 'Error',
                            text: 'El tipo de archivo no se permite',
                            type: 'danger'
                        });
                    }
                }else{
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'El archivo debe pesar menos de 1Mb',
                        type: 'danger'
                    });
                }
            }
        }
    }
}
</script>