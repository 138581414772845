<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
           
            <form class="row top" @submit.prevent="updateNews()">
                <div class="col-md-8 offset-md-2 col-12">
                    <div class="row">
                        <div class="form-group col-12">
                            <label>Titulo</label>
                            <input type="text" v-model="media.title" class="form-control form-control-sm">
                        </div>
                        <div class="form-group col-12">
                            <label>Type</label>
                            <input type="text" v-model="media.type" class="form-control form-control-sm">
                        </div>
                        <div class="form-group col-12">
                            <label>Publisher</label>
                            <input type="text" v-model="media.publisher" class="form-control form-control-sm">
                        </div>
                      
                        <div class="form-group col-md-6 col-12">
                            <label>Author</label>
                            <input type="text" v-model="media.author" class="form-control form-control-sm">
                        </div>
                        <div class="form-group col-md-6 col-12">
                            <label>Date</label>
                            <input type="date" v-model="media.date" class="form-control form-control-sm">
                        </div>
                        <hr>
                        <div class="form-group col-12 " >
                            <button type="submit" class="btn btn-black">Guardar Cambios</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import SideBar from '@/components/SideBarNav.vue'
import TopBar from '@/components/TopBarNav.vue'
import axios from 'axios'
export default {
    name:'NewAdminComponent',
    components:{SideBar,TopBar},
    data(){
        return{
            media: {},
            imageChanged: false,
            images:{
                image_featured:'/assets/no-image.png',
                image_extra1:'/assets/no-image.png',
                image_extra2:'/assets/no-image.png'
            }
        }
    },
    created(){
        if(this.$route.params.id){
            this.getData()
        }
    },
    methods:{
        uploadImage($event,name){
            var image 
            if($event.target.files.length >= 1){
                image =  $event.target.files[0]
            }
            
            if(image.size <= 3000000){
                if( image.type == 'image/jpg' || image.type == 'image/jpeg' || image.type == 'image/png'){
                    this.images[name] = URL.createObjectURL(image)
                    this.media[name] = image
                    this.imageChanged = true
                }else{
                    this.$notify({
                        group: 'iasc',
                        title: 'Error',
                        text: 'El tipo de archivo no se permite',
                        type: 'danger'
                    });
                    this.images[name] = undefined
                }
            }else{
                this.$notify({
                    group: 'iasc',
                    title: 'Error',
                    text: 'El archivo debe pesar menos de 1Mb',
                    type: 'danger'
                });
                this.img_src = undefined
            }
        },
        getData(){
             axios.get(this.$url+'/mediaadmin/'+this.$route.params.id,{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.token
                }
            })
            .then((res)=>{
                this.media = res.data

            }).catch((err)=>{
                console.log(err)
            })
        },
        async updateNews(){
            let data
            var content = ''
            if( this.imageChanged == true && 
              ( (this.images.image_featured != undefined && this.images.image_featured != '/assets/no-image.png') || 
                (this.images.image_extra1 != undefined   && this.images.image_extra1 != '/assets/no-image.png')  || 
                (this.images.image_extra2 != undefined   && this.images.image_extra2 != '/assets/no-image.png'))){

                content = 'multipart/form-data'
                data = new FormData()

                data.append('title',this.media.title)
                data.append('external_link',this.media.subtitle_es)
                data.append('slug',this.media.slug)
                data.append('author',this.media.author)
                data.append('date',this.media.date)
                data.append('long_description',this.media.long_description)
                data.append('short_description',this.media.short_description)
                data.append('external_link',this.media.external_link)
                data.append('media_es',this.media.media_es)

                data.append('image_featured',this.media.image_featured)
                data.append('image_extra1', this.media.image_extra1)
                data.append('image_extra2', this.media.image_extra2)
            }else{
                content = 'application/json'
                data = this.media
            }
            
            axios.post(this.$url+(this.modo == 'Create' ? '/news' : '/news/'+this.$route.params.id),data,{
                headers: {
                    'Content-Type' : content,
                    'Authorization' : this.$store.state.token
                }
            }).then(()=>{
                this.$router.push({name: 'news'})
            })
        }
    }
}
</script>